import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    // 展示治理周报
    {
        path: "/zh/GovernmentWeekly",
        component: () => import('../components/GovernmentWeekly.vue')
    },
    // 微信二维码
    {
        path: "/qrcode.html*",
        component: () => import('../components/qrcode.vue')
    },
    // 中台成本相关
    {
        path: "/zh/MonthlyBills*",
        component: () => import('../components/MonthlyBills.vue')
    },

    //  Demo页面
    // 人像修复
    {
        path: "/zh/ai-demos/faceRestoration",
        component: () => import('../components/demos/function/faceRestoration.vue')
    },
    {
        path: "/en/ai-demos/faceRestoration",
        component: () => import('../components/demos/function/faceRestoration.vue')
    },
    // 人像抠图
    {
        path: "/zh/ai-demos/humansegmentation",
        component: () => import('../components/demos/function/humansegmentation.vue')
    },
    {
        path: "/en/ai-demos/humansegmentation",
        component: () => import('../components/demos/function/humansegmentation.vue')
    },
    // 动漫增强
    {
        path: "/zh/ai-demos/imgRestore",
        component: () => import('../components/demos/function/imgRestore.vue')
    },
    {
        path: "/en/ai-demos/imgRestore",
        component: () => import('../components/demos/function/imgRestore.vue')
    },
    // 万物检测
    {
        path: "/zh/ai-demos/detAny",
        component: () => import('../components/demos/function/objectDetection.vue')
    },
    {
        path: "/en/ai-demos/detAny",
        component: () => import('../components/demos/function/objectDetection.vue')
    },
    // 多模态理解和生成
    {
        path: "/zh/ai-demos/multimodal",
        component: () => import('../components/demos/function/multimodal.vue')
    },
    {
        path: "/en/ai-demos/multimodal",
        component: () => import('../components/demos/function/multimodal.vue')
    },
    {
        path: "/en/ai-demos*",
        redirect: "/en/ai-demos/faceRestoration"
    },
    {
        path: "*ai-demos*",
        redirect: "/zh/ai-demos/faceRestoration",
    },

    // 中文页面
    {
        path: "/zh/index",
        component: () => import('../components/pages/zh/index.vue')
    },
    {
        path: "/zh/careers",
        component: () => import('../components/pages/zh/careers.vue')
    },
    {
        path: "/zh/research",
        component: () => import('../components/pages/zh/research.vue')
    },
    {
        path: "/zh/collaborations",
        component: () => import('../components/pages/zh/collaborations.vue')
    },
    {
        path: "/zh/publications",
        component: () => import('../components/pages/zh/publications.vue')
    },

    // 英文页面
    {
        path: "/en/index",
        component: () => import('../components/pages/en/index.vue')
    },
    {
        path: "/en/careers",
        component: () => import('../components/pages/en/careers.vue')
    },
    {
        path: "/en/research",
        component: () => import('../components/pages/en/research.vue')
    },
    {
        path: "/en/collaborations",
        component: () => import('../components/pages/en/collaborations.vue')
    },
    {
        path: "/en/publications",
        component: () => import('../components/pages/en/publications.vue')
    },

    //官网index
    {
        path: "/*",
        redirect: "/zh/index",
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router