<template>
  <div class="footer">
    <div class="wrap" v-if="windowWidth">
      <div class="logo">
        <img class="tencent" src="../assets/img/tencent.png" alt="" />
        <span class="separate"></span>
        <img class="pcg" src="../assets/img/pcg.png" alt="" />
      </div>
      <div class="text">
        <p>Copyright © 2019 - 2022 Tencent Inc. All Rights Reserved.</p>
      </div>
    </div>
    <div class="wrap_mini" v-if="windowWidth == false">
      <div class="logo">
        <img class="tencent" src="../assets/img/tencent.png" alt="" />
        <span class="separate"></span>
        <img class="pcg" src="../assets/img/pcg.png" alt="" />
      </div>
      <div class="text">
        <p>Copyright © 2019-2022 Tencent Inc. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    return {
      windowWidth: true,
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.footer {
  width: 100%;
  background: #fafafa;
  font-size: 16px;
  .wrap {
    max-width: 1200px;
    height: 1rem;
    margin: 0 auto;
    position: relative;
    .logo {
      .tencent {
        width: 1.49rem;
        height: 0.2rem;
        position: absolute;
        top: 0.4rem;
        left: 1.2rem;
      }
      .separate {
        display: inline-block;
        height: 0.16rem;
        border: 0.01rem solid #d8d8d8;
        position: absolute;
        top: 0.42rem;
        left: 2.85rem;
      }
      .pcg {
        width: 1.89rem;
        height: 0.24rem;
        position: absolute;
        top: 0.38rem;
        left: 3.04rem;
      }
    }
    .text {
      p {
        width: 4.9rem;
        text-align: center;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.14rem;
        color: #666666;
        position: absolute;
        top: 0.4rem;
        left: 5.44rem;
      }
    }
  }
  .wrap_mini {
    width: 7.5rem;
    position: relative;
    height: 2.7rem;
    margin: 0 auto;
    .logo {
      .tencent {
        width: 1.79rem;
        height: 0.24rem;
        position: absolute;
        left: 1.23rem;
        top: 0.6rem;
      }
      .separate {
        display: inline-block;
        width: 1px;
        height: 0.2rem;
        background:#d8d8d8;
        position: absolute;
        top: 0.64rem;
        left: 3.56rem;
      }
      .pcg {
        width: 2.27rem;
        height: 0.29rem;
        position: absolute;
        right: 1.24rem;
        top: 0.58rem;
      }
    }
    .text {
      p {
        width: 3.97rem;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.22rem;
        color: #666666;
        text-align: center;
        line-height: 0.38rem;
        position: absolute;
        top: 1.44rem;
        left: 1.76rem;
      }
    }
  }
}
</style>
