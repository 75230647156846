<template>
  <div class="head">
    <div class="wrap" :class="demo_head ? 'demo_wrap' : ''" v-if="windowWidth">
      <div
        class="top"
        :class="whiteBg ? 'whiteBg' : ''"
        :id="demo_head ? 'demo_head' : ''"
      >
        <div class="inner">
          <div class="logo">
            <a href="Javascript:;" @click="enterHome">
              <img :src="logoImg" alt="" />
            </a>
          </div>
          <div class="navbar" v-if="navbar == 'navbar'">
            <ul>
              <!-- 主页 -->
              <li class="page" v-if="show">
                <router-link :to="'/zh/index'"
                  ><span style="margin-right: 0.06rem">主</span
                  ><span>页</span></router-link
                >
              </li>
              <li class="page" v-else>
                <router-link :to="'/en/index'"><span>Home</span></router-link>
              </li>
              <!-- 研究领域 -->
              <li class="page" v-if="show">
                <router-link :to="'/zh/research'"
                  ><span>研究领域</span></router-link
                >
              </li>
              <li class="page" v-else>
                <router-link :to="'/en/research'"
                  ><span>Research</span></router-link
                >
              </li>
              <!-- 学术成果 -->
              <li class="page" v-if="show">
                <router-link :to="'/zh/publications'"
                  ><span>学术成果</span></router-link
                >
              </li>
              <li class="page" v-else>
                <router-link :to="'/en/publications'"
                  ><span>Publications</span></router-link
                >
              </li>
              <!-- 校企合作 -->
              <li class="page" v-if="show">
                <router-link :to="'/zh/collaborations'"
                  ><span>校企合作</span></router-link
                >
              </li>
              <li class="page" v-else>
                <router-link :to="'/en/collaborations'"
                  ><span>Collaborations</span></router-link
                >
              </li>
              <!-- 招聘信息 -->
              <li class="page" v-if="show">
                <router-link :to="'/zh/careers'"
                  ><span>招聘信息</span></router-link
                >
              </li>
              <li class="page" v-else>
                <router-link :to="'/en/careers'"
                  ><span>Careers</span></router-link
                >
              </li>
              <!-- AI Demo -->
              <li class="page" v-if="show">
                <router-link :class="path.indexOf('/ai-demos/')>-1?'demoPage':''" :to="'/zh/ai-demos/faceRestoration'"
                  ><span>AI Demo</span></router-link
                >
              </li>
              <li class="page" v-else>
                <router-link :class="path.indexOf('/ai-demos/')>-1?'demoPage':''" :to="'/en/ai-demos/faceRestoration'"
                  ><span>AI Demo</span></router-link
                >
              </li>
              <!-- 中英文切换 -->
              <li class="lang">
                <span id="zh" :class="choose ? 'select' : 'uncheck'"
                  ><router-link :to="pathZH"><span>中</span></router-link></span
                >
                <span class="separate"></span>
                <span id="en" :class="choose ? 'uncheck' : 'select'"
                  ><router-link :to="pathEN"><span>En</span></router-link></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="demo_nav" v-if="demo_head">
        <div class="tab">
          <ul class="tab_ul">
            <li v-if="show">
              <router-link :to="'/zh/ai-demos/faceRestoration'"
                ><span>人像修复</span></router-link
              >
            </li>
            <li v-else class="en">
              <router-link :to="'/en/ai-demos/faceRestoration'"
                ><span>Face Restoration</span></router-link
              >
            </li>
            <li v-if="show">
              <router-link :to="'/zh/ai-demos/humansegmentation'"
                ><span>人像抠图</span></router-link
              >
            </li>
            <li v-else class="en">
              <router-link :to="'/en/ai-demos/humansegmentation'"
                ><span>Human Matting</span></router-link
              >
            </li>
            <li v-if="show">
              <router-link :to="'/zh/ai-demos/imgRestore'"
                ><span>动漫增强</span></router-link
              >
            </li>
            <li v-else class="en">
              <router-link :to="'/en/ai-demos/imgRestore'"
                ><span>Animation Enhancement</span></router-link
              >
            </li>
            <li v-if="show">
              <router-link :to="'/zh/ai-demos/detAny'"
                ><span>万物识别</span></router-link
              >
            </li>
            <li v-else class="en">
              <router-link :to="'/en/ai-demos/detAny'"
                ><span>Detect Anything</span></router-link
              >
            </li>
            <li v-if="show">
              <router-link :to="'/zh/ai-demos/multimodal'"
                ><span>多模态理解和生成</span></router-link
              >
            </li>
            <li v-else class="en">
              <router-link :to="'/en/ai-demos/multimodal'"
                ><span>Multimodal Comprehension and Generation</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="banner" style="height: 6rem" v-if="banner1 && !demo_head">
        <div v-show="ZH_lottie">
          <lottie
            class="videoPlay"
            :options="defaultOptions"
            @animCreated="handleAnimation"
          />
        </div>
        <div v-show="!ZH_lottie">
          <lottie
            class="videoPlay_en"
            :options="defaultOptions_en"
            @animCreated="handleAnimation_en"
          />
        </div>
        <div class="ball_ani">
          <div class="star">
            <img src="../assets/img/star.svg" alt="" />
          </div>
          <div class="big_ball">
            <img src="../assets/img/big_ball.svg" alt="" />
          </div>
          <div class="little_ball">
            <img src="../assets/img/little_ball.svg" alt="" />
          </div>
        </div>
        <div class="line_ani" v-if="windowWidth">
          <svg
            class="triangle-canvas"
            viewBox="0 0 1000 600"
            xmlns="http://www.w3.org/2000/svg"
            v-if="showAnimation"
          >
            <polyline
              class="triangle triangle-1"
              points="304,478 463,172 537,172 676,478"
              style="stroke: #5193f2; stroke-width: 0.7; fill: transparent"
            />
            <polyline
              class="triangle triangle-2"
              points="304,478 463,172 537,172 676,478"
              style="stroke: #5193f2; stroke-width: 0.7; fill: transparent"
            />
            <polyline
              class="triangle triangle-3"
              points="304,478 463,172 537,172 676,478"
              style="stroke: #5193f2; stroke-width: 0.7; fill: transparent"
            />
          </svg>
        </div>
      </div>
      <div class="banner" style="height: 4rem" v-show="!banner1 && !demo_head">
        <div class="img_box">
          <img class="head_svg" src="../assets/img/head.png" alt="" />
          <span class="text">{{ text }}</span>
        </div>
      </div>
    </div>
    <div class="wxapp_qrcode" v-if="demo_head && windowWidth">
      <div class="img_box">
        <img src="../assets/img/wxapp.jpg" alt="" />
      </div>
      <p>{{ show ? "微信小程序体验" : "Experience Miniprogram" }}</p>
    </div>
    <div
      class="wrap_mini"
      :class="demo_head ? 'demo_wrap' : ''"
      v-if="windowWidth == false"
    >
      <div v-show="menu == false" class="top" :class="whiteBg ? 'whiteBg' : ''">
        <div class="inner">
          <div class="logo">
            <img :src="logoImg" alt="" />
          </div>
          <span class="icon_box" @click="getMenu()">
            <i id="caidan2_color" class="iconfont icon-caidan2"></i>
          </span>
        </div>
      </div>
      <div v-show="menu == true" class="top menu">
        <div class="inner">
          <div class="logo">
            <img src="../assets/img/logo2.png" alt="" />
          </div>
          <span class="icon_box" @click="revoke()">
            <i class="iconfont icon-guanbi1"></i>
          </span>
        </div>
        <div class="menu_box">
          <ul>
            <!-- 主页 -->
            <li class="page" v-if="show">
              <router-link :to="'/zh/index'"
                ><span class="index" @click="revoke()">主</span
                ><span @click="revoke()">页</span></router-link
              >
            </li>
            <li class="page" v-else>
              <router-link :to="'/en/index'"
                ><span @click="revoke()">Home</span></router-link
              >
            </li>
            <!-- 研究领域 -->
            <li class="page" v-if="show">
              <router-link :to="'/zh/research'"
                ><span @click="revoke()">研究领域</span></router-link
              >
            </li>
            <li class="page" v-else>
              <router-link :to="'/en/research'"
                ><span @click="revoke()">Research</span></router-link
              >
            </li>
            <!-- 学术成果 -->
            <li class="page" v-if="show">
              <router-link :to="'/zh/publications'"
                ><span @click="revoke()">学术成果</span></router-link
              >
            </li>
            <li class="page" v-else>
              <router-link :to="'/en/publications'"
                ><span @click="revoke()">Publications</span></router-link
              >
            </li>
            <!-- 校企合作 -->
            <li class="page" v-if="show">
              <router-link :to="'/zh/collaborations'"
                ><span @click="revoke()">校企合作</span></router-link
              >
            </li>
            <li class="page" v-else>
              <router-link :to="'/en/collaborations'"
                ><span @click="revoke()">Collaborations</span></router-link
              >
            </li>
            <!-- 招聘信息 -->
            <li class="page" v-if="show">
              <router-link :to="'/zh/careers'"
                ><span @click="revoke()">招聘信息</span></router-link
              >
            </li>
            <li class="page" v-else>
              <router-link :to="'/en/careers'"
                ><span @click="revoke()">Careers</span></router-link
              >
            </li>
             <!-- AI Demo -->
             <li class="page" v-if="show">
              <router-link :to="'/zh/ai-demos/faceRestoration'"
                ><span @click="revoke()">AI Demo</span></router-link
              >
            </li>
            <li class="page" v-else>
              <router-link :to="'/en/ai-demos/faceRestoration'"
                ><span @click="revoke()">AI Demo</span></router-link
              >
            </li>
            <!-- 中英文切换 -->
            <li class="lang">
              <p id="zh" :class="choose ? 'select' : 'uncheck'">
                <router-link :to="pathZH"
                  ><span @click="revoke()">中</span></router-link
                >
              </p>
              <span class="separate"></span>
              <p id="en" :class="choose ? 'uncheck' : 'select'">
                <router-link :to="pathEN"
                  ><span @click="revoke()">En</span></router-link
                >
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="banner" style="height: 7.2rem" v-if="banner1 && !demo_head">
        <div v-show="ZH_lottie">
          <lottie
            class="videoPlay"
            :options="defaultOptions_phone"
            @animCreated="handleAnimation_phone"
          />
        </div>
        <div v-show="!ZH_lottie">
          <lottie
            class="videoPlay_en"
            :options="defaultOptions_en_phone"
            @animCreated="handleAnimation_en_phone"
          />
        </div>
        <div class="ball_ani">
          <div class="star">
            <img src="../assets/img/star_phone.svg" alt="" />
          </div>
          <div class="big_ball">
            <img src="../assets/img/big_ball.svg" alt="" />
          </div>
          <div class="little_ball">
            <img src="../assets/img/little_ball.svg" alt="" />
          </div>
        </div>
        <div class="line_ani" v-if="windowWidth == false">
          <svg
            class="triangle-canvas_phone"
            viewBox="0 0 750 720"
            xmlns="http://www.w3.org/2000/svg"
            v-if="showAnimation == false"
          >
            <polyline
              class="triangle_phone triangle-1_phone"
              points="174,598 338,277 414,277 558,598"
              style="stroke: #5193f2; stroke-width: 0.7; fill: transparent"
            />
            <polyline
              class="triangle_phone triangle-2_phone"
              points="174,598 338,277 414,277 558,598"
              style="stroke: #5193f2; stroke-width: 0.7; fill: transparent"
            />
            <polyline
              class="triangle_phone triangle-3_phone"
              points="174,598 338,277 414,277 558,598"
              style="stroke: #5193f2; stroke-width: 0.7; fill: transparent"
            />
          </svg>
        </div>
      </div>
      <div
        class="banner"
        style="height: 5.9rem"
        v-show="!banner1 && !demo_head"
      >
        <div class="img_box">
          <img class="head_svg" src="../assets/img/head.png" alt="" />
          <span class="text">{{ text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../assets/js/eventBus";
import lottie from "vue-lottie";
let animationData = require("../assets/index.json");
let animationData_en = require("../assets/index_en.json");
let animationData_phone = require("../assets/index_phone.json");
let animationData_en_phone = require("../assets/index_en_phone.json");
export default {
  name: "Head",
  props: {
    msg: String,
  },
  components: {
    lottie,
  },
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    bus.$on("sendBybus", (data) => {
      this.bannerHeight = data;
    });
    bus.$on("textBybus", (data) => {
      this.text = data;
    });
    this.isRefresh = this.windowWidth;
  },
  data() {
    return {
      show: true,
      choose: true,
      pathZH: "/zh/index",
      pathEN: "/en/index",
      bannerHeight: 600,
      trueBannerHeight: 600,
      whiteBg: false,
      path: "",
      text: "",
      showAnimation: false,
      defaultOptions: { animationData: animationData, loop: false },
      defaultOptions_en: { animationData: animationData_en, loop: false },
      defaultOptions_phone: { animationData: animationData_phone, loop: false },
      defaultOptions_en_phone: {
        animationData: animationData_en_phone,
        loop: false,
      },
      anim: "",
      anim_en: "",
      anim_phone: "",
      anim_en_phone: "",
      logoImg: require("../assets/img/logo.png"),
      navbar: "navbar",
      windowWidth: false,
      isRefresh: false,
      menu: false,
      details: false,
      banner1: true,
      ZH_lottie: true,
      demo_head: false,
    };
  },
  watch: {
    $route(to) {
      this.path = to.path;
      // 中英文切换
      if (to.path.slice(1, 3) == "zh") {
        this.show = true;
        this.choose = true;
        this.pathZH = to.path;
        this.pathEN = to.path.replace("/zh/", "/en/");
      } else {
        this.show = false;
        this.choose = false;
        this.pathEN = to.path;
        this.pathZH = to.path.replace("/en/", "/zh/");
      }
      if (this.path == "/zh/index") {
        this.banner1 = true;
        this.ZH_lottie = true;
        this.demo_head = false;
      } else if (this.path == "/en/index") {
        this.banner1 = true;
        this.ZH_lottie = false;
        this.demo_head = false;
      } else {
        this.banner1 = false;
        if (/(ai-demos)/.test(this.path)) {
          this.demo_head = true;
        } else {
          this.demo_head = false;
        }
      }
    },
    windowWidth(newVal) {
      if (this.isRefresh != newVal) {
        location.reload();
      }
    },
  },
  methods: {
    enterHome() {
      if (this.path.indexOf("/en") == 0) {
        this.$router.push("/en/index");
      }
      if (this.path.indexOf("/en") == -1) {
        this.$router.push("/zh/index");
      }
    },
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.getWidth();
      if (top > this.trueBannerHeight - 35 && this.path.indexOf("/ai-demos") == -1) {
        this.whiteBg = true;
        this.logoImg = require("../assets/img/logo2.png");
      } else {
        this.whiteBg = false;
        this.logoImg = require("../assets/img/logo.png");
      }
    },
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth < 1032) {
        if (this.bannerHeight == 400 || this.bannerHeight == 0) {
          this.trueBannerHeight =
            (this.bannerHeight * window.innerWidth * 1.475) / 1032;
        } else if (this.bannerHeight == 600) {
          this.trueBannerHeight =
            (this.bannerHeight * window.innerWidth * 1.2) / 1032;
        }
      } else if (1032 < window.innerWidth && window.innerWidth < 1200) {
        this.trueBannerHeight = (this.bannerHeight * window.innerWidth) / 1200;
      } else {
        this.trueBannerHeight = this.bannerHeight;
      }
      var that = this;
      if (window.innerWidth > 1032) {
        that.windowWidth = true;
        setTimeout(function () {
          that.showAnimation = true;
        }, 500);
      } else if (window.innerWidth < 1032) {
        that.windowWidth = false;
        setTimeout(function () {
          that.showAnimation = false;
        }, 500);
      }
    },
    chooseZH() {
      this.choose = true;
      let i = window.location.href.indexOf("/en/");
      if (i != -1) {
        window.location.href = window.location.href.replace("/en/", "/zh/");
      }
    },
    chooseEN() {
      this.choose = false;
      let i = window.location.href.indexOf("/zh/");
      if (i != -1) {
        window.location.href = window.location.href.replace("/zh/", "/en/");
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    handleAnimation_en(anim) {
      this.anim_en = anim;
    },
    handleAnimation_phone(anim) {
      this.anim_phone = anim;
    },
    handleAnimation_en_phone(anim) {
      this.anim_en_phone = anim;
    },
    // 获取菜单
    getMenu() {
      this.menu = true;
    },
    // 撤销菜单
    revoke() {
      this.menu = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.head {
  width: 100%;
  font-size: 0.16rem;
  min-height: 0.8rem;
  .wrap {
    .top {
      width: 100%;
      height: 0.8rem;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      .inner {
        max-width: 1200px;
        height: 0.8rem;
        margin: 0 auto;
        position: relative;
        .logo {
          width: 1.18rem;
          height: 0.3rem;
          position: absolute;
          top: 0.25rem;
          left: 0.21rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .navbar {
          position: absolute;
          top: 0.3rem;
          right: 0.6rem;
          ul {
            .page {
              float: left;
              margin-right: 0.5rem;
              a {
                display: inline-block;
                height: 0.2rem;
                line-height: 0.2rem;
                font-family: PingFangSC-Semibold, sans-serif;
                font-weight: 500;
                font-size: 0.16rem;
                color: #ffffff;
                padding-bottom: 0.12rem;
                position: relative;
                box-sizing: content-box;
              }
              .router-link-active {
                border-bottom: 0.02rem solid #fff;
              }
              .demoPage{
                border-bottom: 0.02rem solid #fff;
              }
              a:before {
                content: "";
                width: 0px;
                height: 0.02rem;
                background: #fff;
                position: absolute;
                top: 0.32rem;
                left: 50%;
                transition: all 0.3s;
                -webkit-transition: all 0.3s; /* Safari 和 Chrome */
                -moz-transition: all 0.3s; /* Firefox 4 */
                -o-transition: all 0.3s; /* Opera */
              }
              a:hover:before {
                width: 100%;
                left: 0%;
              }
            }
            .lang {
              float: left;
              margin-right: 0px;
              #zh,
              #en {
                display: inline-block;
                width: 0.27rem;
                text-align: center;
                font-size: 0.16rem;
                cursor: pointer;
                position: relative;
                a {
                  color: #fff;
                  font-family: PingFangSC-Semibold, sans-serif;
                  font-weight: 500;
                }
                a:hover {
                  opacity: 1;
                }
              }
              #zh {
                margin-right: 0.15rem;
              }
              #en {
                margin-left: 0.15rem;
              }
              .separate {
                display: inline-block;
                height: 0.1rem;
                border-left: 0.01rem solid #fff;
              }
              .select a {
                opacity: 1;
              }
              .uncheck a {
                opacity: 0.6;
              }
            }
          }
          ul:after {
            content: "";
            height: 0;
            clear: both;
            overflow: hidden;
            display: block;
            visibility: hidden;
          }
        }
        .navbar_small {
          position: absolute;
          top: 0.22rem;
          right: 0.6rem;
          .el-dropdown-link {
            cursor: pointer;
            display: inline-block;
            width: 1rem;
            text-align: center;
            color: #ffffff;
            font-size: 0.28rem;
          }
        }
      }
    }
    .whiteBg {
      background: #ffffff;
      box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      -webkit-box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      -o-box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      .inner {
        .navbar ul .page {
          a {
            color: #222;
          }
          .router-link-active {
            border-bottom: 0.02rem solid #222;
          }
          a:before {
            content: "";
            width: 0px;
            height: 0.02rem;
            background: #222;
            position: absolute;
            top: 0.32rem;
            left: 50%;
            transition: all 0.3s;
            -webkit-transition: all 0.3s; /* Safari 和 Chrome */
            -moz-transition: all 0.3s; /* Firefox 4 */
            -o-transition: all 0.3s; /* Opera */
          }
          a:hover:before {
            width: 100%;
            left: 0%;
          }
        }
        .navbar ul .lang {
          .separate {
            border-left: 0.01rem solid #222;
          }
          .select a {
            color: #222;
          }
          .uncheck a {
            color: #999;
          }
          #zh,
          #en {
            a {
              color: #222;
            }
            a:hover {
              color: #222;
            }
          }
        }
      }
    }
    .banner {
      width: 100%;
      overflow: hidden;
      position: relative;
      background: #19103d 100%;
      .videoPlay,
      .videoPlay_en {
        height: 6rem !important;
        width: 10rem !important;
      }
      .ball_ani {
        width: 10rem;
        height: 6rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -5rem;
        overflow: hidden;
        z-index: 100;
        .star {
          width: 100%;
          position: absolute;
          bottom: -0.28rem;
          left: 0;
          animation: star-animation 0.6s linear;
          -webkit-animation: star-animation 0.6s linear;
          -moz-animation: star-animation 0.6s linear;
          -o-animation: star-animation 0.6s linear;
          img {
            width: 100%;
            height: 100%;
          }
        }
        @keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.28rem;
          }
        }
        @-webkit-keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.28rem;
          }
        }
        @-moz-keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.28rem;
          }
        }
        @-o-keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.28rem;
          }
        }
        .big_ball {
          width: 1.13rem;
          height: 1.13rem;
          position: absolute;
          top: 1.3rem;
          right: 1.1rem;
          animation: big_ball-animation 0.6s linear;
          -webkit-animation: big_ball-animation 0.6s linear;
          -moz-animation: big_ball-animation 0.6s linear;
          -o-animation: big_ball-animation 0.6s linear;
          opacity: 1;
          img {
            width: 1.03rem;
            height: 1.03rem;
          }
        }
        @keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        @-webkit-keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        @-moz-keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        @-o-keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        .little_ball {
          width: 0.43rem;
          height: 0.43rem;
          position: absolute;
          top: 4.15rem;
          left: 0.68rem;
          animation: little_ball-animation 0.6s linear;
          -webkit-animation: little_ball-animation 0.6s linear;
          -moz-animation: little_ball-animation 0.6s linear;
          -o-animation: little_ball-animation 0.6s linear;
          opacity: 1;
          img {
            width: 0.35rem;
            height: 0.35rem;
          }
        }
        @keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
        @-webkit-keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
        @-moz-keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
        @-o-keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
      }
      .line_ani {
        width: 10rem;
        height: 6rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -5rem;
        overflow: hidden;
        .triangle-canvas {
          width: 10rem;
          height: 6rem;
          position: absolute;
          left: 0%;
          top: 0%;
        }
        .triangle {
          -webkit-transform-origin: center center;
          transform-origin: center center;
          animation: triangle-animation 3.9s linear infinite;
          -webkit-animation: triangle-animation 3.9s linear infinite;
          -moz-animation: triangle-animation 3.9s linear infinite;
          -o-animation: triangle-animation 3.9s linear infinite;
          opacity: 0;
        }

        .triangle-1 {
          animation-delay: 2s;
          -webkit-animation-delay: 2s;
          -moz-animation-delay: 2s;
          -o-animation-delay: 2s;
        }

        .triangle-2 {
          animation-delay: 3.3s;
          -webkit-animation-delay: 3.3s;
          -moz-animation-delay: 3.3s;
          -o-animation-delay: 3.3s;
        }

        .triangle-3 {
          animation-delay: 4.6s;
          -webkit-animation-delay: 4.6s;
          -moz-animation-delay: 4.6s;
          -o-animation-delay: 4.6s;
        }

        @keyframes triangle-animation {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 0.8;
          }
          100% {
            transform: scale(3.8);
            -webkit-transform: scale(3.8);
            -moz-transform: scale(3.8);
            -o-transform: scale(3.8);
            opacity: 0;
          }
        }

        @-webkit-keyframes triangle-animation {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(3.8);
            -webkit-transform: scale(3.8);
            -moz-transform: scale(3.8);
            -o-transform: scale(3.8);
            opacity: 0;
          }
        }
        @-moz-keyframes triangle-animation {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(3.8);
            -webkit-transform: scale(3.8);
            -moz-transform: scale(3.8);
            -o-transform: scale(3.8);
            opacity: 0;
          }
        }
        @-o-keyframes triangle-animation {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(3.8);
            -webkit-transform: scale(3.8);
            -moz-transform: scale(3.8);
            -o-transform: scale(3.8);
            opacity: 0;
          }
        }
      }
      .img_box {
        width: 12rem;
        height: 4rem;
        margin: 0 auto;
        position: relative;
        .head_svg {
          width: 6.7rem;
          height: 2.92rem;
          position: absolute;
          top: 1.08rem;
          right: 0.18rem;
        }
        .text {
          display: block;
          height: 0.33rem;
          line-height: 0.33rem;
          font-family: PingFangSC-Semibold;
          font-size: 0.24rem;
          color: #ffffff;
          position: absolute;
          top: 1.84rem;
          left: 0.2rem;
        }
      }
    }
  }
  .demo_wrap {
    height: 1.6rem;
    .demo_nav {
      width: 100%;
      height: 0.8rem;
      position: fixed;
      left: 0;
      top: 0.8rem;
      background: #fff;
      z-index: 1000;
      .tab {
        width: 9.96rem;
        height: 0.8rem;
        margin: 0 auto;
        box-sizing: border-box;
        border-bottom: 1px solid #e4e8ef;
        .tab_ul {
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding-top: 0.44rem;
          li a {
            display: block;
            min-width: 0.8rem;
            height: 0.36rem;
            font-size: 0.2rem;
            line-height: 0.36rem;
            font-family: PingFangSC-Semibold, sans-serif;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            color: #222;
            box-sizing: border-box;
          }
          .en a{
            font-size: 0.14rem;
          }
          .router-link-active {
            color: #2e54eb;
            border-bottom: 0.02rem solid #3a5bfd;
          }
          li a:hover {
            color: #486dff;
          }
        }
      }
    }
  }
  #demo_head {
    background: #19103c;
  }
  .wxapp_qrcode {
    position: fixed;
    top: 4.86rem;
    right: 0;
    width: 1.44rem;
    background: #ffffff;
    border: 0.01rem solid #d9e0e8;
    box-sizing: border-box;
    box-shadow: 0rem 0.04rem 0.15rem rgba(108, 121, 154, 0.201759);
    border-radius: 0.02rem;
    text-align: center;
    cursor: pointer;
    .img_box{
      text-align: center;
      height: 1.2rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    p {
      font-size: 0.12rem;
      line-height: 0.22rem;
      color: #434343;
      text-align: center;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
    }
  }
  .wrap_mini {
    background: #19103d;
    position: relative;
    .top {
      width: 100%;
      height: 1.41rem;
      z-index: 1000;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      .inner {
        position: relative;
        height: 1.41rem;
        .logo {
          width: 2rem;
          height: 0.5rem;
          position: absolute;
          top: 0.45rem;
          left: 0.5rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon_box {
          display: inline-block;
          width: 0.38rem;
          height: 0.38rem;
          line-height: 0.38rem;
          position: absolute;
          top: 0.52rem;
          right: 0.59rem;
          text-align: center;
          cursor: pointer;
          .icon-caidan2 {
            font-size: 0.36rem;
            color: #ffffff;
          }
        }
      }
    }
    .whiteBg {
      position: fixed;
      top: 0;
      right: 0;
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      -webkit-box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      -o-box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.06);
      #caidan2_color {
        color: #000;
      }
    }
    .menu {
      height: 100%;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      z-index: 1000;
      .inner {
        position: relative;
        height: 1.41rem;
        .logo {
          width: 2rem;
          height: 0.5rem;
          position: absolute;
          top: 0.45rem;
          left: 0.5rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon_box {
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          line-height: 0.5rem;
          position: absolute;
          top: 0.46rem;
          right: 0.5rem;
          text-align: center;
          cursor: pointer;
          .icon-guanbi1 {
            font-size: 0.36rem;
            color: #000;
          }
        }
      }
      .menu_box {
        ul {
          margin-top: 0.5rem;
          li {
            text-align: center;
            margin-bottom: 0.5rem;
            a {
              display: inline-block;
              span {
                display: inline-block;
                line-height: 0.67rem;
                font-family: PingFangSC-Semibold;
                font-size: 0.48rem;
                color: #999;
                text-align: center;
              }
              .index {
                padding-right: 0.8rem;
              }
            }
            .router-link-active {
              span {
                color: #000;
              }
            }
          }
          .lang {
            position: relative;
            p {
              display: inline-block;
              padding: 0 0.4rem;
            }
            .separate {
              display: inline-block;
              width: 0.04rem;
              height: 0.51rem;
              background: #999999;
              border-radius: 1px;
              position: absolute;
              left: 50%;
              top: 0.08rem;
              margin-left: 0.02rem;
            }
          }
        }
      }
    }
    .banner {
      overflow: hidden;
      .videoPlay,
      .videoPlay_en {
        height: 7.2rem !important;
        width: 7.5rem !important;
      }
      .ball_ani {
        width: 7.5rem;
        height: 7.2rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -3.75rem;
        overflow: hidden;
        z-index: 100;
        .star {
          width: 100%;
          position: absolute;
          bottom: -0.25rem;
          left: -0.45rem;
          animation: star-animation 0.6s linear;
          -webkit-animation: star-animation 0.6s linear;
          -moz-animation: star-animation 0.6s linear;
          -o-animation: star-animation 0.6s linear;
          img {
            width: 112%;
            height: 100%;
          }
        }
        @keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.25rem;
          }
        }
        @-webkit-keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.25rem;
          }
        }
        @-moz-keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.25rem;
          }
        }
        @-o-keyframes star-animation {
          0% {
            bottom: -1.4rem;
          }
          100% {
            bottom: -0.25rem;
          }
        }
        .big_ball {
          width: 1.13rem;
          height: 1.13rem;
          position: absolute;
          top: 1.3rem;
          right: 1.1rem;
          animation: big_ball-animation 0.6s linear;
          -webkit-animation: big_ball-animation 0.6s linear;
          -moz-animation: big_ball-animation 0.6s linear;
          -o-animation: big_ball-animation 0.6s linear;
          opacity: 1;
          img {
            width: 1.03rem;
            height: 1.03rem;
          }
        }
        @keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        @-webkit-keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        @-moz-keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        @-o-keyframes big_ball-animation {
          0% {
            top: 0.96rem;
            right: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 1.3rem;
            right: 1.1rem;
            opacity: 1;
          }
        }
        .little_ball {
          width: 0.43rem;
          height: 0.43rem;
          position: absolute;
          top: 4.15rem;
          left: 0.68rem;
          animation: little_ball-animation 0.6s linear;
          -webkit-animation: little_ball-animation 0.6s linear;
          -moz-animation: little_ball-animation 0.6s linear;
          -o-animation: little_ball-animation 0.6s linear;
          opacity: 1;
          img {
            width: 0.35rem;
            height: 0.35rem;
          }
        }
        @keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
        @-webkit-keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
        @-moz-keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
        @-o-keyframes little_ball-animation {
          0% {
            top: 4.55rem;
            left: 0.13rem;
            opacity: 0;
          }
          100% {
            top: 4.15rem;
            left: 0.68rem;
            opacity: 1;
          }
        }
      }
      .line_ani {
        width: 7.5rem;
        height: 7.2rem;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -3.75rem;
        overflow: hidden;
        .triangle-canvas_phone {
          width: 7.5rem;
          height: 7.2rem;
          position: absolute;
          left: 0%;
          top: 0%;
        }
        .triangle_phone {
          -webkit-transform-origin: center center;
          transform-origin: center center;
          animation: triangle-animation_phone 3.9s linear infinite;
          -webkit-animation: triangle-animation_phone 3.9s linear infinite;
          -moz-animation: triangle-animation_phone 3.9s linear infinite;
          -o-animation: triangle-animation_phone 3.9s linear infinite;
          opacity: 0;
        }

        .triangle-1_phone {
          animation-delay: 2s;
          -webkit-animation-delay: 2s;
          -moz-animation-delay: 2s;
          -o-animation-delay: 2s;
        }

        .triangle-2_phone {
          animation-delay: 3.3s;
          -webkit-animation-delay: 3.3s;
          -moz-animation-delay: 3.3s;
          -o-animation-delay: 3.3s;
        }

        .triangle-3_phone {
          animation-delay: 4.6s;
          -webkit-animation-delay: 4.6s;
          -moz-animation-delay: 4.6s;
          -o-animation-delay: 4.6s;
        }

        @keyframes triangle-animation_phone {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 0.8;
          }
          100% {
            transform: scale(4.5);
            -webkit-transform: scale(4.5);
            -moz-transform: scale(4.5);
            -o-transform: scale(4.5);
            opacity: 0;
          }
        }

        @-webkit-keyframes triangle-animation_phone {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(4.5);
            -webkit-transform: scale(4.5);
            -moz-transform: scale(4.5);
            -o-transform: scale(4.5);
            opacity: 0;
          }
        }
        @-moz-keyframes triangle-animation_phone {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(4.5);
            -webkit-transform: scale(4.5);
            -moz-transform: scale(4.5);
            -o-transform: scale(4.5);
            opacity: 0;
          }
        }
        @-o-keyframes triangle-animation_phone {
          0% {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(4.5);
            -webkit-transform: scale(4.5);
            -moz-transform: scale(4.5);
            -o-transform: scale(4.5);
            opacity: 0;
          }
        }
      }
      .img_box {
        height: 100%;
        position: relative;
        .head_svg {
          width: 9.25rem;
          height: 4.18rem;
          position: absolute;
          right: -2.5rem;
          top: 2.07rem;
        }
        .text {
          line-height: 0.73rem;
          display: inline-block;
          font-family: PingFangSC-Semibold;
          font-size: 0.52rem;
          color: #ffffff;
          text-align: center;
          position: absolute;
          left: 0.68rem;
          top: 2.62rem;
        }
      }
    }
  }
}
</style>
