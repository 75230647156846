import Vue from 'vue'
import App from './App.vue'
import router from './router'

//element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入iconfont
import './assets/icon/iconfont.css'
import './assets/demosIcon/iconfont.css'
import './assets/font_a6livfou585/iconfont.css';
import './assets/font_qafzh3rw31/iconfont.css';
import './assets/font_ikk50il8g4/iconfont.css'
import './assets/font_mdo21zw3ylc/iconfont.css'
import "./assets/font_hv85sh1tpmo/iconfont.css"

import '@vant/touch-emulator';

//分享
import vshare from 'vshare'
 
Vue.use(vshare)
// 引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.prototype.$axios = axios 
Vue.use(VueAxios, axios)

import iView from 'iview';
import 'iview/dist/styles/iview.css';    // 使用 CSS

// 引入vant组件
import { Overlay } from 'vant';
import { Swipe, SwipeItem } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Overlay);


Vue.use(iView);


Vue.config.productionTip = false;

router.afterEach(() => {
  window.scrollTo(0, 0);
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
