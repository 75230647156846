<template>
  <div id="app">
    <Head v-if="showARC"></Head>
    <router-view class="view" v-if="isRouterAlive"></router-view>
    <Footer v-if="showARC && !demo_footer"></Footer>
    <DemoFooter v-if="demo_footer"></DemoFooter>
  </div>
</template>

<script>
import Head from "./components/Head";
import Footer from "./components/Footer";
import DemoFooter from "./components/DemoFooter.vue";

export default {
  name: "App",
  components: {
    Head,
    Footer,
    DemoFooter,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  mounted() {
    if (
      /(DailyReport)/.test(window.location.href) ||
      /(GovernmentWeekly)/.test(window.location.href) ||
      /(qrcode)/.test(window.location.href) ||
      /(MonthlyBills)/.test(window.location.href)
    ) {
      this.showARC = false;
    } else {
      this.showARC = true;
    }
  },
  data() {
    return {
      isRouterAlive: true,
      showARC: true,
      demo_head: false,
      demo_footer: false,
    };
  },
  watch: {
    $route(to) {
      if (
        /(DailyReport)/.test(to.path) ||
        /(GovernmentWeekly)/.test(to.path) ||
        /(qrcode)/.test(to.path) ||
        /(MonthlyBills)/.test(to.path)
      ) {
        this.showARC = false;
      } else {
        this.showARC = true;
      }
      if (to.redirectedFrom != undefined) {
        this.showARC = true;
        location.reload();
      }
      if (/(ai-demos)/.test(to.path)) {
        this.demo_footer = true;
      } else {
        this.demo_footer = false;
      }
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
.el-dropdown-menu__item {
  a {
    color: #409eff;
    text-decoration: none;
    font-size: 16px;
  }
  .lang {
    .separate {
      display: inline-block;
      height: 10px;
      border-left: 1px solid #ccc;
      margin: 0 10px;
    }
    .select a {
      opacity: 1;
    }
    .uncheck a {
      opacity: 0.6;
    }
  }
}
// 预览图样式
.el-image-viewer__img {
  width: 60%;
  height: auto;
  background: transparent;
  object-fit: contain;
}
#app {
  background: #fff;
  text-rendering: auto;
  font-family: PingFangSC-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  // 清除标签的样式
  .view {
    width: 100%;
  }
  a {
    text-decoration: none;
  }
  /*正常的未被访问过的链接*/
  a:link {
    text-decoration: none;
  }
  /*已经访问过的链接*/
  a:visited {
    text-decoration: none;
  }
  /*鼠标划过(停留)的链接*/
  a:hover {
    text-decoration: none;
  }
  /* 正在点击的链接*/
  a:active {
    text-decoration: none;
  }
  ul,
  li {
    list-style-type: none;
  }
}
</style>
